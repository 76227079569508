import { TwitterOutlined } from "@ant-design/icons"
import { FacebookShareButton, TwitterShareButton } from "next-share"
import FacebookIcon from "public/icons/facebook.svg"
import { VFC } from "react"

import { FollowButtonM } from "./FollowButton"

export const ShareButtons: VFC<{ slug: string, url: string, title: string, xs?: boolean }> = ({ slug, url, title, xs }) => {
  return (
    <div style={{
      display: "flex",
      justifyContent: "flex-end",
      padding: xs ? 0 : "12px 32px 12px 16px",
      position: "sticky",
      right: 0,
      bottom: xs ? "16px" : 0,
    }}>
      <div style={{
        display: "flex",
        flexDirection: xs ? "column" : "row",
        justifyContent: "center",
        alignItems: "center",
        gap: "16px",
        background: "white",
        padding: xs ? "16px 8px" : 0,
        boxShadow: xs ? "0px 2px 2px -1px rgba(61, 47, 142, 0.10), 0px 1px 1px 0px rgba(61, 47, 142, 0.04), 0px 2px 8px 0px rgba(61, 47, 142, 0.08)" : "none",
      }}>
        <FollowButtonM slug={slug} />
        <FacebookShareButton url={url} title={title}>
          <FacebookIcon style={{ width: xs ? "16px" : "20px", height: xs ? "16px" : "20px", display: "flex" }} />
        </FacebookShareButton>
        <TwitterShareButton url={url} title={title} hashtags={["ArtX"]}>
          <TwitterOutlined style={{ fontSize: xs ? "16px" : "20px", display: "flex" }} />
        </TwitterShareButton>
      </div>
    </div>
  )
}

import axios from "axios"
import { Article, Entry, GetEntryBySlugParams } from "libs/contentfulForMagazine"
import useSWR from "swr"

export const useMagazine = (params: GetEntryBySlugParams, fallbackData?: Entry<Article>) => {
  return useSWR<Entry<Article>>(
    ["/api/contentful/magazine_preview", params],
    (path, params) => axios.get<Entry<Article>>(path, { params: params }).then(res => res.data),
    {
      revalidateOnFocus: false,
      fallbackData
    }
  )
}

import axios from "axios"
import { ArticleCard, Entry, GetEntriesByCategoryParams } from "libs/contentfulForMagazine"
import useSWRInfinite from "swr/infinite"

export const useMagazines = (params: GetEntriesByCategoryParams, fallback: { [key: string]: Entry<ArticleCard>[] }) => {
  const getKey = (pageIndex:number, previousPageData:Entry<ArticleCard>[]) => {
    if (previousPageData && !previousPageData.length) return null
    return { path: "/api/contentful/magazines", params: params, page: pageIndex + 1 }
  }

  return useSWRInfinite<Entry<ArticleCard>[]>(
    getKey,
    ({ path, params, page }: { path: string, params: any, page: number }) => axios.get(path, {
      params: {
        ...params,
        page: page,
      }
    }).then(res => {
      if (res.status === 200) return (res.data)
      else throw res
    }),
    {
      fallback: fallback,
      initialSize: 1,
      revalidateFirstPage: false,
      revalidateOnFocus: false
    }
  )
}

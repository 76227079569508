import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { BLOCKS, Document, INLINES, MARKS } from "@contentful/rich-text-types"
import { Typography } from "antd"
import colors from "helpers/colors"
import { urls } from "helpers/urls"
import Link from "next/link"
import { ReactNode } from "react"
import { InstagramEmbed, YouTubeEmbed } from "react-social-media-embed"

export const generateReactComponentFormRichTextDocument = (richTextDocument: Document, xs?:boolean): ReactNode => {
  return documentToReactComponents(richTextDocument, {
    renderMark: {
      [MARKS.BOLD]: (text) => <span style={{ fontWeight: 700 }}>{text}</span>,
      [MARKS.ITALIC]: (text) => <span style={{ fontStyle: "italic" }}>{text}</span>,
    },
    renderNode: {
      [BLOCKS.HEADING_1]: (_node, children) => {
        return <Typography.Title level={1} style={{ paddingBottom: "8px", color: colors.black }}>{children}</Typography.Title>
      },
      [BLOCKS.HEADING_2]: (_node, children) => {
        return <Typography.Title level={2} style={{ paddingBottom: "8px", color: colors.black }}>{children}</Typography.Title>
      },
      [BLOCKS.HEADING_3]: (_node, children) => {
        return <Typography.Title level={3} style={{ paddingBottom: "8px", color: colors.black }}>{children}</Typography.Title>
      },
      [BLOCKS.PARAGRAPH]: (_node, children) => {
        return <Typography.Paragraph style={{ fontSize: "14px", lineHeight: 1.5, paddingBottom: "8px", color: colors.black, whiteSpace: "pre-line" }}>{children}</Typography.Paragraph>
      },
      [BLOCKS.QUOTE]: (_node, children) => {
        return (
          <div style={{ display: "flex", flexDirection: "row", gap: "16px" }}>
            <div style={{ width: "4px", alignSelf: "stretch", borderRadius: "50px", background: colors.black, minWidth: "4px", marginBottom: "22px" }} />
            {children}
          </div>
        )
      },
      [INLINES.HYPERLINK]: (_node, children) => {
        if (_node.data.uri.includes("instagram.com")) {
          return (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <InstagramEmbed url={_node.data.uri} width={xs ? "100%" : "50%"} />
            </div>
          )
        } else if (_node.data.uri.includes("youtube.com") || _node.data.uri.includes("youtu.be")) {
          return <YouTubeEmbed url={_node.data.uri} width="100%" />
        }
        return <a href={_node.data.uri} target="_blank" style={{ textDecoration: "underline" }} rel="noopener noreferrer">{children}</a>
      },

      [BLOCKS.EMBEDDED_ENTRY]: (_node, _children) => {
        const fields = _node.data?.target?.fields
        if (fields === undefined) return <></>

        const description_teaser = fields.description?.length > 100 ? `${fields.description?.substr(0, 100)}...` : fields.description
        return (
          <div style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            margin: "16px 0",
            border: `solid 1px ${colors.grey30}`,
            borderRadius: "4px"
          }}>
            <Typography.Text style={{
              padding: "8px 16px",
              color: colors.grey97,
              fontWeight: 500,
              borderBottom: `solid 1px ${colors.grey30}`
            }}>
              Article
            </Typography.Text>

            <div style={{
              display: "flex",
              flexDirection: "column",
              gap: 8,
              padding: "16px"
            }}>
              <Link href={urls.magazine(`${fields.slug}`)}>
                <a>
                  <Typography.Text style={{ fontWeight: 600, fontSize: 16 }} >{fields.title}</Typography.Text>
                </a>
              </Link>
              <Typography.Text style={{ color: colors.grey97 }}>{description_teaser}</Typography.Text>
            </div>
          </div >
        )
      },
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        // render the EMBEDDED_ASSET as you need
        if (!node.data?.target?.fields?.file) return

        if (node.data?.target?.fields?.file.contentType.includes("video")) {
          return (
            <div style={{ padding: "40px 0" }}>
              <video
                src={`https://${node.data.target.fields.file.url}`}
                height="auto"
                width="100%"
                playsInline
                autoPlay={false}
                controls
                muted
              />
              {/* contentfulで設定したキャプションを使う場合表示 */}
              {/* <Typography.Text style={{ paddingTop: "8px", fontSize: "12px", color: colors.grey30 }}>
                {node.data.target.fields.description}
              </Typography.Text> */}
            </div>
          )
        }

        return (
          <div style={{ padding: "40px 0" }}>
            <img
              src={`https://${node.data.target.fields.file.url}`}
              height="auto"
              width="100%"
              alt={node.data.target.fields.description}
            />
            {/* contentfulで設定したキャプションを使う場合表示 */}
            {/* <Typography.Text style={{ paddingTop: "8px", fontSize: "12px", color: colors.grey30 }}>
              {node.data.target.fields.description}
            </Typography.Text> */}
          </div>
        )
      },
    }
  })
}
